@import "../node_modules/bulma/bulma.sass";

body {
  font-family: "Helvetica Neue", "Helvetica", "Arial", sans-serif;
}

// Responsive overrides
@media screen and (min-width: 769px) and (max-width: 1029px) {
  .column.is-one-fifth {
    width: 30%;
  }
  .column.is-four-fifths {
    width: 70%;
  }
  .column.is-5-tablet {
    width: 41.66667%;
  }
}

@media screen and (min-width: 1030px) and (max-width: 1400px) {
  .column.is-one-fifth {
    width: 25%;
  }
  .column.is-four-fifths {
    width: 75%;
  }
  .column.is-5-tablet {
    width: 33.333%;
  }
}

@media screen and (min-width: 769px) and (max-width: 1400px) {
  .player-score {
    display: none;
  }
}
