@import "../node_modules/bulma/sass/utilities/initial-variables.sass";

.wrapper {
  max-width: 1680px;
  margin: 0 auto;
  padding: 40px;
}

.header {
  margin-bottom: 1.5rem;
}

.main {
  min-height: calc(100vh - 272px);
}

.title--answer-area {
  margin-top: 7px;
  margin-bottom: 30px !important;
}

.card-row {
  &__container {
    overflow: hidden;

    &--has-cards {
      padding-bottom: 1.5rem;
    }    
  }

  @media screen and (max-width: 768px) {
    overflow-x: auto;
    display: flex;
    flex-wrap: nowrap !important;

    > .column {
      min-width: 65vw;
      max-width: 65vw;
    }
  }
}

.uocg-card {
  position: relative;
  box-shadow: 3px 2px 10px 3px rgba(0, 0, 0, 0.05);
  border-radius: 5px;
  border: 1px solid $grey-lighter;
  padding: 1.5rem;
  height: 22rem;
  word-break: break-word;
  overflow: hidden;

  @media screen and (min-width: 961px) and (max-width: 1400px) {
    font-size: 1.125rem !important;

    .is-size-3 {
      font-size: 1.5rem !important;
    }
  }

  @media screen and (min-width: 769px) and (max-width: 960px) {
    font-size: 1rem !important;
    height: 18rem;

    .is-size-3 {
      font-size: 1.25rem !important;
    }
  }

  @media screen and (max-width: 768px) {
    font-size: 1.25rem !important;

    .is-size-3 {
      font-size: 1.5rem !important;
    }
  }

  @media screen and (max-width: 350px) {
    height: 18rem;
    font-size: 1.125rem !important;
  }

  &--in-hand {
    @media (max-width: 768px) {
      padding-bottom: 86px;
    }
  }
  &--question {
    color: $white;
    background: $cyan;

    @media screen and (max-width: 768px) {
      width: 59.2vw;
      margin-left: auto;
      margin-right: auto;
      font-size: 1.125rem !important;
    }
  }
  &--answer {
    cursor: pointer;

    @media (max-width: 768px) {
      padding-bottom: 86px;
    }
  }
  &--empty {
    display: flex;
    flex-direction: column;
    justify-content: center;
    background: $white;

    @media screen and (max-width: 768px) {
      width: 59.2vw;
      margin-left: auto;
      margin-right: auto;
      font-size: 1rem !important;

      .is-size-5 {
        font-size: 1.125rem !important;
      }
    }
  }

  &__footer {
    position: absolute;
    bottom: 1.5rem;
    left: 1.5rem;
    right: 1.5rem;

    &--dynamic {
      display: none;

      @media (max-width: 768px) {
        display: block;
      }
    }
  }

  &:hover {
    .uocg-card__footer--dynamic {
      display: block;
    }
  }
}

.button-section {
  margin-bottom: 1.5rem;

  &--bottom {
    margin-top: 1.5rem;
    margin-bottom: 0;
  }
}

.cards-remaining {
  margin-top: 10px;
}

.uocg-footer {
  background: none;
  font-size: 0.6rem;
  padding-top: 6rem;

  .version {
    font-size: 0.7rem;
    text-align: center;

    @media screen and (min-width: 769px) {
      display: flex;
      height: 100%;
      align-items: flex-end;
      text-align: left;
    }
  }

  .content {
    @media screen and (min-width: 769px) {
      display: flex;
      height: 100%;
      align-items: flex-end;
      justify-content: flex-end;
    }
    @media screen and (max-width: 768px) {
      text-align: center !important;
    }
  }
}

::-webkit-input-placeholder {
  color: $grey-light !important;
}
:-ms-input-placeholder {
  color: $grey-light !important;
}
::placeholder {
  color: $grey-light !important;
}

.connection-error {
  position: fixed;
  left: 0;
  right: 0;
  bottom: 0;
  padding: 5px;
  z-index: 100;
}

.hand-tip {
  @media screen and (max-width: 768px) {
    text-align: center;
  }
}

.game-area {
  @media screen and (max-width: 768px) {
    padding-bottom: 65px;
  }

  &__header {
    display: flex;
    justify-content: space-between;
    
    .title {
      margin-bottom: 0;
    }
  }
}

.rules-link {
  cursor: pointer;
  margin-top: 5px;
}


@media screen and (max-width: 768px) {
  .question-card-section--fixed {
    .uocg-card {
      position: fixed;
      top: 0;
      left: 0;
      right: 0;
      z-index: 10;
      width: 100%;
      height: auto;
    }
  } 

  .modal-content {
    padding-left: 10px;
    padding-right: 10px;

    .title {
      font-size: 1.5rem;
    }

    .uocg-card {
      height: auto;
      width: 100%;
    }
  }
}
